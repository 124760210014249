
<div class="page-background">
	<div class="page-background-grid-overlay"></div>
	<div class="page-background-overlay"></div>
</div>

<div class="inset-card-container">
	<div class="card">

		<div class="card-header">
			<img class="logo" src="./assets/images/Mapware_Logo_CMYK.png">
		</div>

		<div class="card-body">
			<ng-container *ngTemplateOutlet="t_login"></ng-container>
		</div>

		<div class="card-footer">
			<ng-container *ngTemplateOutlet="t_loginActions"></ng-container>
		</div>

	</div>
</div>

<ng-template #t_login>
	<div *ngIf="showError_invalidInfo && !showError_toManyAttempts"
		class="login-error">
		<mat-error class="H7RedRegular">
			Invalid email and/or password. Please try again.
		</mat-error>
	</div>

	<div *ngIf="showError_toManyAttempts"
		class="login-error">
		<mat-error class="H7RedRegular">
			Too many sign in attempts, please try again later.
		</mat-error>
	</div>

	<form novalidate
		name="form"
		[formGroup]="loginFormGroup"
		(ngSubmit)="appLogin()"
		id="login_form">

		<input type="submit" style="display: none" />

		<!-- Email --
		<div class="input-header" style="margin-top: 24px;">Email</div> -->
		<mat-form-field subscriptSizing="dynamic" appearance="fill" class="login-input">
			<mat-label>Email</mat-label>
			<input matInput
				type="email"
				name="email"
				placeholder="john.doe@email.com"
				formControlName="email"
				autofocus>
		</mat-form-field>

		<!-- Password --
		<div class="input-header" style="margin-top: 24px;">Password</div> -->
		<mat-form-field subscriptSizing="dynamic" class="login-input">
			<mat-label>Password</mat-label>
			<input matInput
				type="password"
				name="password"
				placeholder="********"
				formControlName="password">
		</mat-form-field>

		<div class="nav-link login-forgot-password">
			<a routerLink="/forgot">Forgot your password?</a>
		</div>
	</form>
</ng-template>
<!-- END-OF t_login -->

<ng-template #t_loginActions>
	<!-- Submit -->
	<div class="card-actions">
		<button mat-flat-button
				color="primary"
				class="main-card-button"
				type="submit"
				value="Login"
				(click)="appLogin()"
				[disabled]="!loginFormGroup.valid">
			Sign In
		</button>
	</div>

	<ng-container *featureEnabled="'!disable-signup'">
		<ng-container *ngIf="googleAuthAvailable">
			<!-- or divider-->
			<div class="signin-divider">
				<div class="half-line"></div>
				or
				<div class="half-line"></div>
			</div>

			<div class="card-actions">
				<div class="custom-google-button main-card-button" #googleLogin id="my-signin2" (click)="googleOnClick()">
					<div class="custom-google-icon"></div>
					<span class="gText">Sign In with Google</span>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *featureEnabled="'!disable-signup'">
		<div class="nav-container">
			New to Mapware? <a class="nav-link" routerLink="/register">Create a free account</a>
		</div>
	</ng-container>
</ng-template>
