/* Imports */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as Http from '@angular/common/http';
import * as Router from '@angular/router';
import * as Forms from '@angular/forms';
// File imports
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import * as Modules from './shared/modules/main.module';

import * as Services from './shared/services';
import * as Directives from './shared/directives';
import * as Guards from './shared/guards';
import * as Components from './components';

import * as Forgot from './pages/forgot';
import * as NotFound from './pages/notfound';
import * as Invite from './pages/invite';
import * as Login from './pages/login';
import * as Rename from './pages/rename';
import * as Tags from './pages/tags';
import * as Verification from './pages/verification';
import * as Onboarding from './pages/onboarding';
import * as Questionnaire from './pages/questionnaire';
import * as Account from './pages/account';

import * as Providers from './shared/providers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PagingStreamService } from '@shared/services/paging-stream.service';
import {ErrorHandlerInterceptor} from '@shared/interceptors/errorHandler.interceptor';
import {PlanInterceptor} from '@shared/interceptors/plan.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';

import { SharedModule } from "@shared/shared.module";

const authenticationConfiguration = new Services.AuthenticationServiceConfiguration([
	{
		id: Providers.GoogleProvider.PROVIDER_ID,
		provider: new Providers.GoogleProvider(environment.googleAuthClientID)
	}
]);

/* Material */
import { MaterialModule } from "./shared/modules/material.module";

@NgModule( {
    declarations: [
        AppComponent,
        Forgot.ForgotComponent,
        Login.LoginComponent,
        // Pages
        Questionnaire.QuestionnaireComponent,
        Onboarding.OnboardingComponent,
        NotFound.NotFoundComponent,
        Invite.InviteComponent,
        Verification.VerificationComponent,
        Rename.RenameModal,
        Tags.TagsModal,
        Account.AccountComponent,
        Account.ProfileComponent,
        Account.PlanComponent,
        Account.PlanDialog,
        Account.TeamsComponent,
        Account.ReceiptComponent,
        Account.NotificationComponent,
        Components.InformationModal,
        Components.UploadPhotosDialog,
        Components.MoveImagesDialog,
        Components.ConfirmationModal,
        Components.ErrorModal,
        Components.ProjectsFilterComponent,
        Components.AerialGridItem,
        Components.AerialFloatingBar,
        Components.ShareComponent,
        Components.ShareLinkComponent,
        Components.AnnotationsComponent,
		Components.NewPlanComponent,
        Directives.UploadDirective,
        Directives.AlertDirective,
        Directives.DownloadDirective,
        Directives.ProcessDirective
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        Modules.MainModule,
        DragDropModule,
        Router.RouterModule,
        Forms.FormsModule,
        Forms.ReactiveFormsModule,
        MaterialModule,
        Http.HttpClientModule,
        BrowserAnimationsModule,
    ],
    providers: [
        // Guards
        Guards.UserGuard,
        Guards.DevGuard,
        Guards.MaintenanceGuard,
        // Services
        Services.AuthenticationService,
        {
            provide: Services.AuthenticationServiceConfiguration,
            useFactory: () => authenticationConfiguration
        },
        Services.AdminService,
		Services.AIModelService,
        Services.AlertService,
        Services.ExportService,
        Services.FavoriteService,
        Services.FileService,
        Services.FinanceService,
        Services.GCPService,
        Services.HttpService,
        Services.ImageService,
        Services.ModelService,
        Services.MeasurementService,
        Services.AnnotationService,
        Services.NavigationService,
        Services.OrderService,
        Services.OrganizationService,
        Services.PermissionService,
        Services.ProjectService,
        Services.ScaleConstraintService,
        Services.ShareService,
        Services.SearchService,
        Services.TitleService,
        Services.TrashService,
        Services.UploadService,
        Services.UserService,
        Services.UrlService,
        Services.UtilsService,
        Services.PotreeService,
        PagingStreamService,
        // Interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
