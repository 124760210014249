/* Imports */
import {Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {Router, NavigationStart, NavigationCancel, NavigationError, NavigationEnd} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {flagLayer, availableFeatureFlags} from "@shared/featureFlags";

/* Services */
import {
	AlertService,
	OrganizationService,
	AuthenticationService,
	GlobalService,
	UtilsService,
	UploadService,
	ModelService,
	SessionService,
	AdminService
} from './shared/services';
import {InformationModal} from './components/information/information.modal';
import {analyticsLayer} from '@shared/analyticsLayer';
import {Subscriber} from "rxjs";

// Add routes here that should not have the process, upload, and download notifications shown
const disabledNotificationsRoutes = [
	'/map',
	'/v?'
];

const checkDisabledNotificationsRoutes = (url) => {
	return disabledNotificationsRoutes.some(route => url.includes(route));
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public navItems: Array<any>;
	public showNotifications: boolean = false;
	public isMobile: boolean = false;
	public loading: boolean = true;
	public mobileNotificationsOpen: boolean = false;
	public numNotifications: number = 0;
	public showNumNotifications: boolean = false;
	public subscriptions: { upload: any, process: any } = {
		upload: {subscription: null, value: 0},
		process: {subscription: null, value: 0},
	};
	@ViewChild('ref', {read: ElementRef, static: false}) elementView: ElementRef;


	constructor(
		public dialog: MatDialog,
		public _router: Router,
		public _alertService: AlertService,
		private _cdr: ChangeDetectorRef,
		private _orgService: OrganizationService,
		private _authenticationService: AuthenticationService,
		private _utilsService: UtilsService,
		private _uploadService: UploadService,
		private _modelService: ModelService,
		private _adminService: AdminService
	) {

		this.isMobile = this._utilsService.getIsMobile();

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.isMobile = isMobile;
		})

		analyticsLayer.initialize();

		this._router.events.subscribe(event => {

			if (!this.loading && event instanceof NavigationStart) {
				this.loading = true;
			} else if (this.loading && (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)) {
				this.loading = false;
				/// clear page hider
				$('#pageLoadingHider').addClass("invis");
			}

			if (event instanceof NavigationEnd) {

				const isLoggedIn = this._authenticationService.user && this._authenticationService.user.email_verified;

				this.showNotifications = isLoggedIn
					&& !checkDisabledNotificationsRoutes(event.url)
					&& !flagLayer.isEnabled(availableFeatureFlags.disableMapware);
				this._cdr.detectChanges();

				if (isLoggedIn) {
					analyticsLayer.identify(this._authenticationService.user.id.toString());

					this.subscribeToNotifications();
				}
			}
		});
	}	// End-of constructor


	ngOnInit() {

		// Print version
		GlobalService.getVersion();

		this._orgService.getOrgChange().subscribe(rtn => {
			if (rtn?.type === 'added') {
				this.dialog.open(InformationModal, {
					data:
						{
							title: 'Your account is now part of a team',
							text: 'Your account has now been added to “' + rtn.org.name + '”. Refresh your webpage when convenient to access the team’s data. You can find your team’s admin(s) and members under your profile.'
						}
				});
			} else if (rtn?.type === 'removed') {
				this.dialog.open(InformationModal, {
					data:
						{
							title: 'Your account has been removed from a team',
							text: 'Your account has been removed from “' + rtn.org.name + '”. If this is an error please contact your team’s admin(s).'
						}
				});
			}
		});
	}	// End-of OnInit

	ngOnDestroy() {
		Object.values(this.subscriptions).forEach(({subscription}) => {
			if (subscription instanceof Subscriber) subscription.unsubscribe();
		})
	}

	hideOverlay(): void {
		this._router.navigate([{outlets: {overlay: null}}]);
	}

	relativeToAlert = () => {
		return {bottom: $('#alert-container').outerHeight() + 'px'};
	}

	subscribeToNotifications() {

		this.subscriptions.upload.subscription = this._uploadService.projectListChange.subscribe(({renderState}) => {
			this.subscriptions.upload.value = renderState.status.allFinished ? 0 : renderState.projectList.length;
			this.updateNumNotifications(); // Update notifications value on change
		})
	}


	updateNumNotifications() {

		let numNotifications = 0;
		Object.values(this.subscriptions).forEach(({value}) => {
			numNotifications += value;
		})
		if (this.numNotifications !== numNotifications) {
			this.showNumNotifications = true;
			this.numNotifications = numNotifications;
		}
		;
	}

	displayNumNotifications(): string {
		return this.numNotifications > 9 ? '9+' : '' + this.numNotifications;
	}

	setMobileNotifications(): void {
		this.mobileNotificationsOpen = !this.mobileNotificationsOpen;
		this.showNumNotifications = false;
	}

}	// End-of class AppComponent
